@charset 'utf-8';

$primary:#1B9EDB;
$Secondary:#6ec4e8; //
$brLight: #f4f4f4;
$combrc:#f4f4f4;
$compda: 16px;
$comheight: 48px;
$comShadow: 0 2px 4px 0 rgba(0,0,0,.1);
$Shadow0: none;
$gray_color:#777;
$com_bp:rgba(0,0,0,0.25);//border point color //
$com_bc:rgba(0,0,0,0.05);//border common color //