@charset 'utf-8';
@import "~@/assets/styles/variable.scss";
@font-face {
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 700;
	src: url(~@/assets/font/SpoqaHanSansNeo-Bold.woff2) format("woff2"),
		url(~@/assets/font/SpoqaHanSansNeo-Bold.woff) format("woff"),
		url(~@/assets/font/SpoqaHanSansNeo-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 500;
	src: url(~@/assets/font/SpoqaHanSansNeo-Medium.woff2) format("woff2"),
		url(~@/assets/font/SpoqaHanSansNeo-Medium.woff) format("woff"),
		url(~@/assets/font/SpoqaHanSansNeo-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 400;
	src: url(~@/assets/font/SpoqaHanSansNeo-Regular.woff2) format("woff2"),
		url(~@/assets/font/SpoqaHanSansNeo-Regular.woff) format("woff"),
		url(~@/assets/font/SpoqaHanSansNeo-Regular.ttf) format("truetype");
}
@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 300;
	src: local('Spoqa Han Sans Light'),
	url(~@/assets/font/SpoqaHanSansNeo-Light.woff2) format('woff2'),
	url(~@/assets/font/SpoqaHanSansNeo-Light.woff) format('woff'),
	url(~@/assets/font/SpoqaHanSansNeo-Light.otf) format('opentype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 100;
	src: local('Spoqa Han Sans Thin'),
	url(~@/assets/font/SpoqaHanSansNeo-Thin.woff2) format('woff2'),
	url(~@/assets/font/SpoqaHanSansNeo-Thin.woff) format('woff'),
	url(~@/assets/font/SpoqaHanSansNeo-Thin.otf) format('opentype');
}
.ico_com {
	color: #fff;
	font-size: 0;
}
.back {
	display: inline-block;
	background: url("~@/assets/images/back.svg") no-repeat;
	width: 24px;
	height: 24px;
}
.menu {
	display: inline-block;
	background: url("~@/assets/images/menu.svg") no-repeat;
	width: 16px;
	height: 14px;
}
.variant {
	display: inline-block;
	background: url("~@/assets/images/variant.svg") no-repeat;
	width: 16px;
	height: 16px;
}
.search1 {
	display: inline-block;
	background: url("~@/assets/images/search1.svg") no-repeat;
	width: 20px;
	height: 20px;
}
.flex_row{
	display:flex;
	flex-direction: row;
}
.left {
	text-align: left;
}
.center {
	text-align: center;
}
.right {
	text-align: right;
}
table td {
	color: #555 !important;
}
strong {
	font-weight: normal;
	font-family: "Spoqa Han Sans Neo";
	font-weight: 700;
}
.v-data-table a {
	text-decoration: underline;
}
.mdata_table .txt_total {
	margin-bottom: 10px;
	color: #555;
	letter-spacing: -0.02em;
}
.mdata_table .txt_total span {
	color: #000;
	margin-left: 3px;
	display: inline-block;
	font-weight: 500;
}
.ellipsis {
	position: relative;
	top: 5px;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}
//text custom
.txt_info01 {
	display: flex;
	align-items: center;
	background: #f7f7f7;
	font-size: 14px;
	padding: 10px 12px;
}
.txt_info01 i {
	padding-right: 5px;
}
.txt_r {
	color: #ef2121;
}
.txt_r .v-icon {
	color: #ef2121;
}
/*txt_r 까지 삭제가능*/

.txt_b {
	color: #1b9edb;
}
.txt_b .v-icon {
	color: #1b9edb;
}
.txt_r .v-icon,
.txt_b .v-icon {
	width: 15px;
	position: relative;
	top: -2px;
}
.w_50 {
	width: 50%;
}
.pd_0 {

	padding: 0;
}
.no_header {
	padding-top: 0px !important;
}

.front_mob { height: 100%; }

.v-application {
	font-family: "Spoqa Han Sans Neo", "맑은 고딕", "Malgun Gothic" !important;
	font-weight: 400;
	&--wrap {  height: 100vh; }
}
.v-input--switch,
.v-input--radio-group {
	margin-top: 0 !important;
}
.v-application ul,
.v-application ol {
	padding-left: 0px;
}
body {
	min-width: 300px;
	font-family: "Spoqa Han Sans Neo", "맑은 고딕", "Malgun Gothic" !important;
	letter-spacing: -0.01em;
	font-weight: 400;
	color: #222;
}
// body p,
// body ul,
// body ol,
// body li,
// body dl,
// body dt,
// body dd {
// 	list-style-image: none;
// 	list-style-position: outside;
// 	list-style-type: none;
// 	margin: 0;
// 	padding: 0;
// }
body .v-card__title {
	padding: 0;
}

/**************************************veutify basic custom *****************************/

//v-sidebar-menu
body .v-sidebar-menu .vsm--item .vsm--link_level-2 {
	display: block;
	padding: 5px 0 5px 50px !important;
	font-size: 14px;
}
body .v-sidebar-menu .vsm--item .vsm--link_level-2.vsm--link_active {
	background: rgba(244, 244, 244, 0.5);
}
body .v-sidebar-menu .vsm--scroll-wrapper {
	margin-right: 0 !important;
}
body .v-sidebar-menu.vsm_white-theme {
	z-index: 100;
	padding-top: 72px;
	border-right: 1px solid #eee;
}
body .v-sidebar-menu.vsm_white-theme .vsm--link {
	padding: 0;
	font-weight: 500;
	color: #555;
}
body .v-sidebar-menu.vsm_white-theme .vsm--link_level-1 {
	padding: 10px 15px;
}
body .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
	background: rgba(244, 244, 244, 0.5);
}
body .v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
	margin: 0 7px 0 5px;
	font-size: 1.2em;
	background: none;
}
body .v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
	background: #fff;
	padding: 15px 5px;
}
body .v-sidebar-menu.vsm_white-theme .vsm--link.vsm--link_active {
	color: #1b9edb;
	box-shadow: none;
}
body .v-sidebar-menu.vsm_white-theme .vsm--header {
	height: 64px;
	padding-top: 0;
	font-size: 22px;
	line-height: 64px;
	vertical-align: baseline;
	letter-spacing: 1px;
	color: #5c6bc0;
}
body .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active .vsm--icon,
body .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
	color: #1b9edb;
	background: inherit;
}
body .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 {
	color: #1b9edb;
	background-color: #fff;
}
body .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
	color: inherit;
	background-color: #fff;
}
body .v-sidebar-menu .vsm--arrow:after {
	font: normal normal normal 24px/1 "Material Design Icons";
	line-height: 32px;
	content: "\F0142";
	vertical-align: top;
}
body .v-sidebar-menu .vsm--link {
	margin: 0;
}

//v-dialog custom
.v-dialog {
	z-index: 100;
	border-radius: 10px;
	.v-card{
		border-radius: 0;
	}
	.v-btn.bottom_btn{
		border-radius: 0 !important;
	}
	.btn_wrap{
		margin-top: 0;
	}
}
.v-dialog--fullscreen {
	background-color: #fff;
	border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	-o-border-radius: 0 !important;
	.v-card { border-radius:0; }
	// header 공통
	.popup_header {
		height: 56px;
		.popup_header_title{
			line-height: 56px;
			font-weight: bold;
			text-align: center;
			border-bottom: 1px solid #ebebeb;
			font-size: 15px;
		}
		.v-btn {
			position: absolute;
			top: 10px;
			right: 12px;
		}
	}
	// top 
	.v-btn {
		&.btn_top {
			background: #fff !important;
			border-radius: 16px !important;
			border: none !important;
			-webkit-border-radius: 16px !important;
			-moz-border-radius: 16px !important;
			-ms-border-radius: 16px !important;
			-o-border-radius: 16px !important;
			box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.3) !important;
			.v-btn__content {
				flex-direction: column;
				font-size: 9px;
				letter-spacing: 0;
				> i.v-icon { height: 16px; }
			}
		}
		&.btm { bottom: 76px; }
	} 
}
.v-dialog .v-card__title {
	border-bottom: 1px solid #f4f4f4;
	justify-content: center;
}
.v-dialog .v-card__text {
	padding: 25px;
	color: #3e3e3e;
}
.v-dialog .v-card__actions .v-btn {
	min-width: 80px !important;
}
.v-dialog .v-card__actions {
	padding: 16px;
}
.v-dialog .dialog_fixed_con {
	padding: 56px 0px 0 0px;
}
.v-dialog .dialog_fixed_con .row {
	margin-top: 16px;
}
.v-dialog h3 {
	color: #000;
}
.v-dialog .v-toolbar__content {
	position: relative;
}
.v-dialog .v-toolbar__content .btn_close {
	position: absolute;
	top: 4px;
	right: 12px;
}
.v-dialog .fixed_tit {
	font-size: 1.25rem;
	line-height: 1.5;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #fff;
}
.v-dialog .v-app-bar.primary {
	background-color: #81bdda !important;
	border-color: #81bdda !important;
}
.v-dialog .v-data-table__wrapper th,
.v-dialog .v-data-table__wrapper td {
	padding: 0 !important;
}
.v-dialog__content .btn_cl_modal {
	height: 60px;
	font-size: 30px;
	margin: 0px -10px 0 0;
}
.v-dialog .v-card__actions {
	padding: 0;
}
.v-dialog .v-card__actions .btn_wrap {
	width: 100%;
	padding: 0;
}
.v-dialog .v-card__actions .btn_wrap button {
	margin-right: 0;
	border-radius: 0;
	height: 48px;
}
.v-dialog .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
// v-input, v-text-field
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
	> .v-input__control
	> .v-input__slot fieldset { color: #ebebeb;}

//label custom
.v-application--is-ltr .v-text-field .v-label {
	font-size: 14px;
}
.v-text-field.v-input--is-disabled {
	color: rgba(0, 0, 0, 0.38);
	background: #f1f2f4;
}
.v-input--switch--inset {
	&.primary--text {
		.v-input--switch__track {
			color:$primary !important;
			caret-color: $primary !important;
		}
		.v-input--switch__thumb {
			color: #fff !important;
			caret-color: #fff !important;
		}
	}
	.v-input--switch__track {
		opacity: 1;
		color: #cbd0d9 !important;
	}
	.v-input--selection-controls__ripple {
		opacity: 0.2;
	}
}

.v-input.v-text-field.primary--text {
	color: #222 !important;
	caret-color: #222 !important;
}
.v-input.v-text-field.primary--text .v-icon {
	color: #222 !important;
}
.v-input input {
	caret-color: #222;
}
//v-picker
.v-picker .v-date-picker-header {
	padding: 16px 16px 4px 16px;
	&__value{
		font-size: 20px;
		button {
			letter-spacing: -0.08em;
			font-size: 20px;
			font-weight: 500;
		}
	} 
}

.v-picker .v-date-picker-table {
	height: auto;
	padding: 0 12px 20px 12px;
	th {
		border-top: thin solid rgba(0, 0, 0, 0);
		border-bottom: thin solid rgba(0, 0, 0, 0);
	}
	tbody button {
		font-weight: 400;
	}
	&--month  .v-btn--active {
		border-radius: 4px !important;
	}
	&--date .v-btn {
		width: 35px;
		height: 35px;
		font-size: 13px;
	}
	.v-btn--active,  .v-btn--outlined{
		border-radius: 50%;
		&::before {
			opacity: 0;
		}
	}
	// month select
	.v-btn--active.accent--text, .v-btn--outlined.accent--text{
		border-radius: 4px;
		color: $primary !important;
		caret-color: $primary !important;
	}
	.v-btn--active.accent, .v-btn--outlined.accent{
		background-color: $primary !important;
		border-color: 1px solid $primary !important;
	}
}
.v-picker .v-picker__actions {
	display: flex;
	> button {
		flex: 1;
		height: 48px !important;
		border-radius: 0;
		margin: 0 !important;
	}
	.spacer {
		display: none;
	}
}
// v-date-picker button color Custom
// 달력 custom 
.v-picker {
	.v-date-picker-header__value button{
		font-size: 17px;
		font-weight: 600;
	}
	&__body {
		z-index: 99;
	}
}


//v-data-table custom 
.v-data-table .text-center .v-input--switch {
	width: 45px;
	margin: 0 auto;
}
.v-data-table {
	table {
		th, td {
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
			border-right: 0;
		}
		th {
			background: #f4f4f4;
			border-left: 1px solid rgba(0, 0, 0, 0.06);
			&:first-child {
				border-left: 0;
			}
		}
		td {
			border-top: 0 !important;
			border-left: 1px solid #eee;
			&:first-child {
				border-left: 0;
			}
		}
	}
	.v-data-table__checkbox, .v-input--checkbox {
		margin-left: 5px;
	}
	.check_custom {
		font-size: 14px;
		.v-input {
			margin-top: 0px;
			padding-top: 5px;
			&__control {
				flex-direction: inherit;
				flex-wrap: nowrap;
			}
		}
		.v-input--selection-controls__input {
			margin-right: 0px;
		}
	}
	&-header .text-start {
		text-align: center !important;
	}
	&__wrapper td {
		font-size: 0.875rem;
		display: table-cell;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
//v-overlay
.v-overlay--active .v-overlay__scrim {
	opacity: 0.8 !important;
	&.white {
		opacity: 0.46 !important;
	}
}
//progress (loading)
.loading {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	display: flex;
	text-align: center;
	background-color: rgba(112, 112, 112, 0.5);
	> div.v-progress-circular{
		margin: auto;
	}
}
// progress-circular 위치 
.v-progress-circular {
	left: calc(50% - 32px);
	margin-top: 20px;
	position: absolute;
}
//v-snackbar
.v-snack__wrapper.theme--dark{
	background-color: #222;
	border-radius: 12px;
	color: #fff;
	min-width: 300px;
	position: fixed;
	bottom: 58px;
	.v-snack__content {
		font-size: 13px;
		padding: 8px 16px;
	}
}
//v-size
.v-size--large.default,
.v-size--default.default {
	color: #1b9edb !important;
	background-color: #fafafa !important;
}

.v-breadcrumbs {
	font-weight: 500;
}
.v-tab {
	font-size: 17px;
	font-weight: 500;
}

.v-divider {
	border-color: $brLight !important;
}
//input 공통
.search_area {
	margin: 0 !important;
	position: relative;
	.v-input{
		position:relative;
		&__slot{
			border-radius: 12px;
			.v-label{
				color:#999;
			//	top: 15px;
			}
			.v-icon{
				color:#222;
			}
		}
		.v-text-field__slot{
			padding-right: 35px;
		}
	}
	// v-input size 커스텀
	.v-text-field--filled > .v-input__control > .v-input__slot, 
	.v-text-field--full-width > .v-input__control > .v-input__slot, 
	.v-text-field--outlined > .v-input__control > .v-input__slot{
		min-height: 48px !important;
		//label 값 수정
		 .v-label{
			top: 15px;
			line-height: 1.2;
		}
	}
	//아이콘 여백값
	.v-input__append-inner{
		margin-top: 12px !important;
	}
	//버튼 형태 아이콘 위치값
	.v-btn{
		position: absolute;
		right: 10px;
		border-radius: 50%;
		top: 12px;
		width: 25px;
		height: 25px !important;
		display: inline-block;
		&.underline{
			position: absolute;
			right: 0;
			top: 8px; 
		}
	}
	//언더라인 input 
	.v-text-field > .v-input__control > .v-input__slot:before, 
	.v-text-field > .v-input__control > .v-input__slot:after{
		bottom: -6px;
	}
}
//인풋에 감싸주는 div 없을때
.search_area.v-input {
	position: relative;
	// v-input size 커스텀
	&.v-text-field--filled > .v-input__control > .v-input__slot, 
	&.v-text-field--full-width > .v-input__control > .v-input__slot, 
	&.v-text-field--outlined > .v-input__control > .v-input__slot{
		min-height: 48px !important;
		//label 값 수정
		.v-label{
			top: 15px;
			line-height: 1.2;
		}
	}
	//아이콘 여백값
	.v-input__append-inner{
		margin-top: 0 !important;
		align-self: center;
	}
	//언더라인 input 
	&.v-text-field > .v-input__control > .v-input__slot:before, 
	&.v-text-field > .v-input__control > .v-input__slot:after{
		bottom: -6px;
	}
}

//textarea
.search_area .v-input.v-textarea{
	.v-text-field__slot{
		padding-right: 0;
	}
}
/************************veutify basic custom END*************************/


/**************************vant basic custom*****************************/
//van custom
.van-index-bar__sidebar {
	background: rgba(0, 0, 0, 0.12);
	padding: 10px 0;
	border-radius: 5px;
}
.van-index-bar__index--active {
	color: #1b9edb !important;
}
.van-index-bar__index {
	padding: 0 3px !important;
}
.van-loading {
	color: #1b9edb !important;
}
.van-list .van-loading__text {
	display: none;
}
.van-list .van-loading__spinner.van-loading__spinner--circular {
	width: 22px !important;
	height: 22px !important;
}
.van-list .van-loading__spinner.van-loading__spinner--circular .van-loading__circular circle {
	stroke-width: 7px !important;
}

/**************************vant basic custom END*****************************/


/*************프로젝트 별 CSS / 고객 문자발송 안내, 코디풀, 스마트세일즈, 수수료**************/


.fixed_btn {
	position: fixed;
	z-index: 1;
	width: 100%;
	bottom: 0;
	padding: 12px;
	background: rgba(255, 255, 255, 0.98);
	left: 0;
}
.overflow-y-hidden body {
	overflow: hidden;
}
.scroll-table {
	border-bottom: thin solid #eee !important;
}
thead > tr:last-child > th {
	border-top: thin solid rgba(0, 0, 0, 0.12);
	border-bottom: thin solid rgba(0, 0, 0, 0.06);
}
// btn_wrap 코디풀, 스마트, 1:1문의 프론트, web admin = 버튼 공통
//버튼 전체공통
.v-dialog .btn_wrap{
	width: 100%;
}
.v-btn:not(.v-btn--round).v-size--large {
	height: 56px;
}
.btn_wrap {
	display: flex;
	width: auto;
	padding: 0 !important;
	margin-top: $compda;
	.v-btn {
		border-radius: 0;
		margin-right: 0px;
		font-size: 16px;
		font-weight: 500;
		&--rounded {
			border-radius: 12px;
		}
		.v-icon {
			margin-right: 7px;
		}
	}
	button {
		flex: 1;
		margin-right: 10px;
		:last-child {
			margin-right: 0;
		}
	}
	i {
		margin-left: 7px;
	}
}
.btn_wrap.plus {
	width: 120px;
	margin: 0 auto;
	.v-btn {
		border-radius: 25px;
		margin-bottom: 20px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
		i {
			margin-right: 0;
		}
	}
}
//하단 고정 버튼
.btn_wrap_fix{
    position:fixed;
    left:0;
    bottom:0;
    width: 100%;
	z-index: 1;
	.v-btn:not(.v-btn--round).v-size--large{
        width: 50%;
		border-radius: 0 !important;
    }
	.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		background-color: #f1f2f4 !important;
		color: #c1c6cd !important;
	}
	//하단 고정 width 100%
	.bottom_btn_fix{
		width: 100%;
	}
	.v-btn__content {
		font-size: 16px;
	}
}
// flex 버튼 정렬
.btn_wrap_flex{
	display:flex;
	flex-direction:row;
	.v-btn{
		width:50%;
		border-radius: 0;
		//하단 고정 width 100%
		&.bottom_btn_fix{
			width: 100%;
		}
	}
}
.bottom_btn{
	border-radius: 12px !important;
}
.v-btn--contained{
	box-shadow: none !important;
}
.v-btn--rounded {
	border-radius: 12px;
}
//흰색 버튼
.btn_w {
	background-color: #fff !important;
	border: 1px solid #ddd !important;
	color: #555 !important;
}
// text 버튼
.v-btn.primary--text.v-btn--outlined {
	background-color: #fff;
}
// disabled 버튼 공통 
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) 
	{
		background-color: #f1f2f4 !important;
		color: #c1c6cd !important;
	}
//top 버튼 공통
.v-btn {
	&.btn_top {
		background: #fff !important;
		border-radius: 16px !important;
		border: none !important;
		-webkit-border-radius: 16px !important;
		-moz-border-radius: 16px !important;
		-ms-border-radius: 16px !important;
		-o-border-radius: 16px !important;
		box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.3) !important;
		.v-btn__content {
			flex-direction: column;
			font-size: 9px;
			letter-spacing: 0;
			> i.v-icon { height: 16px; }
		}
	}
	&.btm { bottom: 76px; }
} 
//check_btn 체크 라인 버튼
.check_btn:not(.v-btn--round).v-size--default{
    border-radius: 12px;
    border-color: #ebebeb;
    padding: 10px;
    min-width: auto;
    height: auto;
    letter-spacing: -0.1px;
    &.theme--light.v-btn:hover{
        border: 1px solid $primary;
        ::before{
            opacity: 1;
            background-color: #fff;
        }
        span.v-btn__content{
            color: $primary;
        }
    }
}
//에러페이지 error page, 코디풀 codyPool
.message_wrap {
	display: flex;
	justify-content: center;
	text-align: center;
	background: #fff;
	align-items: center;
	height: 100vh;
	min-width: 320px; 
	.message_area {
		min-width: 320px;
		.btn_wrap {
			width: calc(100%  - 12px);
			margin: 0 auto;
		}
		.txt_wrap, .sub_txt_wrap {
			word-break: keep-all;
			&.bold {
				font-weight: bold;
			}
		}
		.icon_wrap .v-icon {
			color: rgba(0, 0, 0, 0.15);
		}
	}
}
// 에러메세지 web ver
.message_wrap.web {
	min-height: 700px;
	height: calc(100% - 80px);
	width: calc(100% - 24px);
	.txt_wrap {
		padding: 20px 0 30px 0 !important;
		font-size: 24px;
		line-height: 38px;
	}
	.v-icon {
		font-size: 100px;
	}
	.sub_txt_wrap {
		color: #555;
		padding: 30px;
		margin-bottom: 20px;
		font-size: 15px;
		line-height: 26px;
	}
	.btn_wrap {
		width: 400px;
		margin: 0 auto;
	}
	.message_area {
		padding: 150px 20px;
	}
}
// 에러메세지 mob ver
.message_wrap.mob {
	.txt_wrap {
		padding: 24px 0;
		font-size: 18px;
	}
	.v-icon {
		font-size: 80px;
	}
	.sub_txt_wrap {
		font-size: 14px;
		color: #535353;
		padding: 24px 0;
	}
}
//button default 스타일
.v-size--large.default,
.v-size--default.default {
	color: #1b9edb !important;
	background-color: #f4f4f4 !important;
}
//search_box admin, fron_mob 컨텐츠, 스마트 세일즈 = 검색박스 공통
.search_box {
	width: 100%;
	// .search_area
	.search_area {
		display: flex;
		align-items: center;
		font-size: 15px;
		background: #fff;
		> div {
			margin-right: 10px;
		}
		.btn_select {
			height: 40px;
			padding: 0 8px;
			margin-right: 10px;
			width: 30%;
		}
		.v-btn {
			height: 48px;
			border-radius: 10px;
		}
	}
	//.new_search_area
	.new_search_area {
		display: flex;
		align-items: center;
		font-size: 15px;
		background: #fff;
		&.input_pd input {
			padding-left: 70px;
		}
		.v-input {
			border-radius: 25px !important;
		}
		.btn_select {
			position: absolute;
			border-color: rgba(0, 0, 0, 0) !important;
			height: 40px;
			z-index: 1;
			padding: 0 5px 0 15px;
		}
		.btn_search {
			background: #fff !important;
			padding: 0 10px;
		}
		.v-btn {
			height: 48px;
			border-radius: 10px;
			min-width: 0;
		}
		input {
			padding-left: 10px;
		}
	}
	//.search_detail 
	.search_detail {
		border-bottom: 1px solid #ddd;
		background: #fff;
		padding: 10px 16px;
		border-top: 1px solid #f4f4f4;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.txt01 {
			font-size: 15px;
		}
		.v-btn {
			padding: 0px;
			font-size: 14px;
		}
		strong {
			color: #1b9edb;
		}
		.btn_area {
			.v-btn {
				margin: 0 5px;
				i {
					font-size: 22px;
					margin-left: 5px;
				}
				&::after {
					content: "";
					display: inline-block;
					width: 1px;
					height: 14px;
					background: #ddd;
					margin: 0px;
				}
				&:first-child i::before{
					margin-right: 5px;
				}
				&:last-child::after {
					width: 0;
					margin: 0px;
				}
			}
		} 
	}
}
// tab 
//tab with scroll event
.tab_box {
	margin-top: 12px;
	margin-bottom: 50px;
	.v-tabs {
		margin-top: 0 !important;
		&.fixed {
			position: fixed;
			top: 0;
			left: 16px;
			width: calc(100% - 32px);
			z-index: 500;
		}
	}
}
// dialog 안에 tab_box 는 앱에서 적용시 로딩바 노출을 위해 bottom값이 더 들어가야함.
.v-dialog .tab_box {
	margin-bottom: 120px;
}
//tab custom
.v-slide-group__wrapper{
    border-bottom:1px solid #ebebeb;
    width:calc(100% - 32px);
    height: 55px;
    background-color:#fff;
    z-index: 2;
}
.v-item-group.primary--text{
    color: #222 !important;
    &.v-tabs-bar{
        height: 55px;
    }
}
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev{
    display: none;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color:#c1c1c1;
    font-weight:normal;
    font-family:'Spoqa Han Sans Neo, Regular';
    letter-spacing: 0;
}
.v-tab{
    font-size: 14px;
    min-width: 80px;
    padding:0 7px;
    position:relative;
    &::before {
        background-color: #fff;
    }
    .tab_title{
        position:relative;
		&.check::after{
			position:absolute;
			content:''   ;
			width:4px;
			height:4px;
			border-radius: 50%;
			right:-7px;
			top: 0;
			background-color:#1B9EDB;
		}
    }
}
.v-tabs-slider-wrapper{
    display:none;
}
.v-tab--active {
    border-bottom: 2px solid #222;
}
//tab custom 끝


.overflow-y-hidden .mob_com .v-overlay {
	z-index: 100 !important;
}
.mob_com .header_wrap .v-overlay {
	top: 0px !important;
}
//코디풀 codyPool
.mob_type .v-text-field {
	font-size: 15px;
	border-radius: 10px !important;
}
.mob_type .v-text-field .v-input__slot {
	min-height: auto !important;
	display: flex !important;
	align-items: center !important;
	height: 48px;
}
.mob_type .v-text-field .v-input__slot .v-text-field__slot {
	height: 48px;
}
.mob_type .v-text-field .v-input__slot .v-label {
	top: 0;
}
.mob_type .v-text-field .v-input__slot .v-input__append-inner,
.mob_type .v-text-field .v-input__slot .v-input__prepend-inner {
	height: 48px;
	margin-top: 0px !important;
	display: flex;
	align-items: center;
}
.mob_type .v-text-field .v-label {
	height: 48px;
	line-height: 48px;
}

//data table
.mob_type .v-data-table table {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
	color: #555;
	font-size: 14px !important;
	padding: 12px 0;
}
.mob_type .v-data-table th {
	background: #f4f8fb !important;
	text-align: center !important;
	height: 40px !important;
	font-size: 14px !important;
	line-height: 40px !important;
	font-weight: 500 !important;
	color: #333 !important;
}
.mob_type .v-data-table th:last-child {
	border-right: 0;
}
.mob_type .v-data-table th .v-data-table__checkbox {
	margin-left: 0;
}
.mob_type .v-data-table tr {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.mob_type .v-data-table td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	padding: 0 10px !important;
}
.mob_type .v-data-table td:nth-child(1) {
	padding: 0 !important;
}
.mob_type .v-data-table td .v-input--checkbox {
	width: 24px;
	margin: 0 auto !important;
}
.mob_type .v-data-table th,
.mob_type .v-data-table td {
	font-size: 14px;
	font-weight: 500;
	border-bottom: 1px solid #eee !important;
	border-left: 0px solid #eee;
	border-right: 1px solid #eee !important;
}
.mob_type .btn_wrap {
	box-shadow: 0px 2px 3px 3px rgba(0, 0, 0, 0.08);
}
.mob_type .btn_wrap .txt_are .txt01 {
	color: #222;
}

// 스마트세일즈, 코디풀, (구)프론트
.mob_com .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
	border-bottom: thin solid rgba(0, 0, 0, 0.06);
}
.mob_com .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
	color: #fff !important;
	background-color: #eee !important;
}
.mob_com .row.scroll-table .col {
	border-left: 1px solid #eee;
}
.mob_com .row.scroll-table .col:first-child {
	border-left: 0;
}
.mob_com .row.scroll-table a {
	text-decoration: underline;
}
.mob_com .v-input .mdi-close:before {
	background: #f4f4f4;
	border-radius: 50%;
	font-size: 18px;
	padding: 3px;
}
.mob_com .v-data-table-header th {
	font-size: 14px !important;
	height: 40px !important;
}
.mob_com .v-virtual-scroll {
	color: #555;
}
.mob_com .v-text-field {
	font-size: 15px;
}
.mob_com .v-text-field .v-input__slot {
	min-height: auto !important;
	display: flex !important;
	align-items: center !important;
	height: 48px;
}
.mob_com .v-text-field .v-input__slot .v-text-field__slot {
	height: 48px;
}
.mob_com .v-text-field .v-input__slot .v-label {
	top: 0;
}
.mob_com .v-text-field .v-input__slot .v-input__append-inner,
.mob_com .v-text-field .v-input__slot .v-input__prepend-inner {
	height: 48px;
	margin-top: 0px !important;
	display: flex;
	align-items: center;
}
.mob_com .v-text-field .v-label {
	height: 48px;
	line-height: 48px;
}
.mob_com .header_wrap .navi_menu {
	background: #f4f4f4;
	box-shadow: none;
}
.mob_com .header_wrap .navi_menu.v-navigation-drawer {
	position: fixed;
	top: 56px !important;
}
.mob_com .header_wrap .navi_menu .v-sidebar-menu {
	position: relative;
}
.mob_com .header_wrap .navi_menu .v-sidebar-menu.vsm_white-theme {
	z-index: 100;
	padding-top: 0px;
	border-right: 0;
}
.mob_com .v-app-bar.gnb_wrap {
	z-index: 8;
	border-bottom: 1px solid #f4f4f4;
	background-color: #fff !important;
}
.mob_com .v-app-bar.gnb_wrap .v-btn {
	border-radius: 50% !important;
}
.mob_com .v-app-bar .v-toolbar__title {
	font-weight: 700;
	font-size: 15px;
}
.mob_com input,
.mob_com button,
.mob_com .v-input,
.mob_com .v-btn {
	height: 48px;
	border-radius: 10px;
	letter-spacing: -0.04em;
	font-size: 15px;
}
.mob_com input,
.mob_com button,
.mob_com .v-input,
.mob_com .v-btn,
.mob_com .v-chip {
	font-weight: 400;
	font-family: "Spoqa Han Sans Neo", "맑은 고딕", "Malgun Gothic" !important;
}
.mob_com .v-chip.v-size--small {
	font-size: 13px;
}
.mob_com .v-list-item__title {
	font-size: 14px !important;
}
.mob_com .v-text-field {
	font-size: 15px;
}
.mob_com .v-text-field .v-input__slot {
	min-height: auto !important;
	display: flex !important;
	align-items: center !important;
	height: 48px;
}
.mob_com .v-text-field .v-input__slot .v-text-field__slot {
	height: 48px;
}
.mob_com .v-text-field .v-input__slot .v-label {
	top: 0;
}
.mob_com .v-text-field .v-input__slot .v-input__append-inner,
.mob_com .v-text-field .v-input__slot .v-input__prepend-inner {
	height: 48px;
	margin-top: 0px !important;
	display: flex;
	align-items: center;
}
.mob_com .v-text-field .v-label {
	height: 48px;
	line-height: 48px;
}
.mob_com .v-btn.v-size--default {
	font-size: 15px;
}
//molecules 
.circular_wrap {
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
	padding: 10px 0;
	background: rgba(255, 255, 255, 1);
}
.circular_wrap .v-progress-circular {
	width: 25px !important;
	height: 25px !important;
}
.mlist_scroll {
	padding-bottom: 30px;
}

.theme--light.v-tabs-items {
	background-color: initial;
}

//수수료, 스마트 세일즈, 코디풀
.panels_list_wrap {
	border-radius: 0px;
	.v-expansion-panel {
		background-color: inherit !important;
		color: rgba(0, 0, 0, 0.87);
		:not(:first-child)::after {
			border-color: #eee !important;
		}
		&-header {
		height: 65px;
		padding: 16px 0px;
		}
		button {
			border-color: #000;
		}
		// -content 
		&-content{
			.v-expansion-panel-content__wrap {
				padding: 0;
			}
		}
	}
	.header_title {
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		color: #555;
	}
}

.line_tab_wrap .v-slide-group__wrapper {
	border-bottom: 1px solid #eee;
}

.login .v-card {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

//해약, 코디풀, 컨텐츠프론트
.sub_tit {
    flex-direction: row;
	margin-bottom: 7px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.v-btn {
		height: 32px;
		border-radius: 20px !important;
		&.v-btn--rounded {
			border-radius: 20px !important;
		}
	}
}

//정렬 공통
.flex-align{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	
}