
                    @import "@/assets/styles/common.scss";
                    @import "@/assets/styles/variable.scss";
                
@charset 'utf-8';



.coway_admin{
	min-width:1024px;
	margin-left: 230px;
	transition: .3s;
	.btn_toggle_nav{
		display:none
	}
	.datepicker_wrap{
		padding-bottom: 0px;
	}
	.dot_new{
		position: absolute;
		top: 10px;
		right: 0px;
		margin-left:7px;
		font-style: normal;
		background: #e75f58;
		color: #fff;
		display: inline-block;
		width: 13px;
		height: 13px;
		font-size: 8px;
		border-radius: 3px;
		span {
			position: relative;
			top: -2px;
			left: 3px;
			font-size: 11px;
		}
	}
	//그림자 box-shadow none 처리
	.v-btn  {
		box-shadow: none !important;
	}
	.elevation-1 {
		box-shadow: none !important;
	}
	//등록, 수정 페이지 테이블
	.table_vertical{
		border: 1px solid rgba(0, 0, 0, 0.12);
		border-radius: 0;
		table{
			min-width: 800px;
		}
		th{
			width:220px;
			min-width: 220px;
			padding: 20px;
			font-size: 16px;
			text-align: left;
			color: #333;
			font-weight: 500;
			border-top: 0px !important;
			background: #f4f4f4;
			border-bottom: 1px solid #f4f4f4;
		}
		td{
			border-bottom: 1px solid #f4f4f4;
			padding: 13px 20px;
			&.line_01 .date_picker {
				margin-top: 10px;
			}
			.file_input {
				margin-top: 20px;
			}
			//썸네일 이미지 옆 리스트 타입 텍스트
			.list_type{
				margin-left: 400px;
				margin-bottom: 110px;
			}
			.add_txt span {
				display: block;
				position: relative;
				margin-bottom: 10px;
				padding-left: 14px;
				font-size: 0.9em;
				line-height: 1.5;
			}
			.add_txt span:before {
				display: block;
				position: absolute;
				top: 9px;
				left: 3px;
				width: 4px;
				height: 4px;
				content: "";
				border-radius: 5px;
				background: #999;
			}
		}
		tr{
			min-height: 100px;
			&:last-child th,
			&:last-child td{
				border-bottom: 0;
			}
		}
		.table_col{
			display:flex;
			align-items: center;
			width:100%;
			.v-input--radio-group__input{
				min-width:250px;width:30%
			}
			.switch_wrap{
				min-width:100px;
			}
			.datepicker_wrap{
				width:480px;
			}
			.textfield_wrap{
				width:100%;
			}
		}
	}
	.table_01 {
		overflow: auto;
	}
	.table_01 table {
		min-width: 800px;
	}
	.v-text-field--outlined fieldset {
		border-width: 1px !important;
	}
	
	.input_chip{
		position:relative;
		&.file{
			padding-right: 120px;
			display:flex;
			flex-wrap: wrap;
			.v-btn{
				position:absolute;
				right:0;
				top:5px;
				.v-icon{
					font-size:18px
				}
			}
		}
		.v-application .ma-2 {
			margin: 4px !important;
		}
	}

	//v-data-table 정렬
	.v-data-table{
		.v-data-table__wrapper {
			td{
				white-space:nowrap;
				background-color:#fff;
			}
		}
		table{
			th{
				background: #f4f4f4;
				font-size: 16px !important;
			}
			td{
				border-top:0 !important;
				font-size: 16px !important;
			}
			th,td{
				border-top: 1px solid rgba(0, 0, 0, 0.12);
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
				border-left: 1px solid rgba(0, 0, 0, 0.12);
				border-right:0;
				&:first-child{
					border-left:0
				}
			}
			.v-data-table__checkbox,.v-input--checkbox{
				margin-left:5px
			}
		}
	}

	//테이블 내 버튼 정렬
    .table_heading{
		padding: 0 0 0px 0;
		.wrap_btn {
			margin: 0px 0 12px 0;
            display:flex;
            justify-content:flex-end;
            width:100%;
			.v-btn{
				margin-left:10px
			}
		}
		.btn_col_4 {
			display: flex;
			max-width: 100px;
			width: 12%;
			margin-left: 10px;
		}
		.btn_area{
			display:flex;
            justify-content: flex-end;
            padding:12px;
            padding-top:0px;
			.v-btn,button{
                margin-left:10px;
                min-width:85px;
				&:first-child{
					margin-left:0
				}
			}
		}
	}

	.vsm--dropdown {
		.vsm--list {
			padding:5px !important
		}
	}
	
	.v-size--default{
		min-width:100px !important
	}
	.main_tit_wrap{
		position:relative;
		&.v-card__title{
			position:relative !important
		}
		display:flex;
		justify-content: space-between;
		.btn_area{
			button{
				margin-left:10px;top:0
			}
		}
	}
	.v-card__title.v-card__title{
		position:absolute
	}
	.main_search_wrap{
		.list_wrap{
			display:flex;
			width:100%;
			flex-wrap:wrap;
			margin:15px 0;
			padding:15px 0px 5px 0;
			//justify-content: space-between;
			border-top:1px solid #999;
			.col_date_wrap{
				width:40%;
			}
			li{
				display:flex;
				width:30%;
				align-items:center;
				height:45px;
				padding:0 20px;
				margin-bottom:10px;
				.tit{
					min-width:100px;
					&::before {
						content:'';
						display:inline-flex;
						width:5px;
						height:5px;
						background:#999;
						border-radius: 50%;
						position:relative;
						top:-3px;
						margin-right:5px
					}
				}
				.con{
					width:calc(100% - 100px);
				}
			}
		}
		.btn_action{
			display:flex;
			justify-content: flex-end;
			.v-icon{
				font-size:22px;
				margin-right:5px;
			}
		}
	}
	
	.require:after{
		content:'*';
		display:inline-block;
		color:#ed320d;
		margin-top:-5px
	}
	.container{
		position:absolute;
		top:50px;
		bottom:0;
		right:0;
		left:230px;
		height:calc(100% -50px);
		width:auto;
		padding:0;
		> div{
			min-width:800px;
		}
		> .row{
			> .col{
				> .v-card{
					padding:15px 30px!important;
				}
			}
		}
	}
	
	.v-sheet.v-card {
    border-radius: 0px;
	box-shadow: none;
	}
	.v-sheet.v-app-bar.v-toolbar{
		position: fixed;
		z-index: 110;
		min-width:1024px
	}
	.wrap_btn_col_2 button,.wrap_btn_col_2 a {
		margin-left: 20px
	}
	.v-toolbar__content{
		background: #81bdda;
	}
	.container.login{
		max-width: 800px;
	}
	.i_dot{
		width:10px;
		height:10px;
		display:inline-block;
		border-radius:50%;
		margin-right:5px;
    &.color_01 {
		background: #e75f58;
	}
		&.color_02 {
			background: #55b023;
		}
		&.color_03 {
			background: #878787;
		}
		&.color_04 {
			background: #fff;
			border:2px solid rgba(0,0,0,0.2)
		}
	}
	.search_wrap{
		margin-bottom:5px
	}
	.mdata_table{
		th{font-size:0.85rem !important}
		.v-pagination{
			.v-pagination__item,.v-pagination__navigation{
				height: 40px !important;
    		width: 40px !important;
				border-radius: 0px;
			}
			.v-pagination__item {
				background: transparent;
				border-left:0px;
				font-size: 1rem;
				height: 34px;
				margin: 0;
				min-width: 34px;
				padding: 0 5px;
				text-decoration: none;
				transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
				width: auto;
				
			}
		}
	}
	.datepicker_wrap{
		width:100%;
		display:flex;
		align-items:center;
		> div{
			padding:0;
			width:calc(50% - 10px);
		}
		.txt01{
			position:relative;
			top:2px;
			margin:0 7px
		}
	}
	
	.w_50 {
		float: left;
		width: 50%;
	}

	.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: #f4f4f4;
	}

	/* page */
	.box_login {
		max-width: 520px;
		margin: 0 auto;
		padding: 140px 20px;
		background: #fff;
	}
	.img_wrap {
		.v-image {
			margin: 16px 0 0 0;
		}
		.txt_con {
			margin-top: 16px;
			font-size: 15px;
			font-weight: 500;
		}
		p{
			margin-bottom: 0px;
			color: #222;
			font-weight: 400;
		}
		 p:first-child {
			margin-top: 20px;
			margin-bottom: 0px;
		}
		
		img {
			padding: 10px 0px;
			height: auto!important;
			max-width: 100%;
			overflow: hidden;
		}
	}
}

//common to admin custom.scss test
//v-sheet
.coway_admin {
	.v-sheet.v-app-bar {
		box-shadow: none !important;
	}
	.v-sheet.v-app-bar.v-app-bar--is-scrolled {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
	}
}
.coway_admin .main_search_wrap .list_wrap {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.coway_admin .theme--light.v-data-table.v-data-table--fixed-header thead th {
	box-shadow: none;
}

//mdata_table custom
.coway_admin .mdata_table{
	.v-pagination{
		li:nth-child(2) .v-pagination__item {
			border-left: 1px solid #f4f4f4;
		}
		&__navigation{
			border: 1px solid #f4f4f4;
			box-shadow: none;
		}
		&__item{
			border: 1px solid #f4f4f4;
			border-left: 0;
			box-shadow: none;
		}
	}
}


.v-toolbar__content{
	.infor{
		display:flex;
		align-items: center;
		font-size:15px;
		.btn_area{
			.v-icon{
				margin-right:7px
			}
		}
		.txt_user{
			margin-left:30px;
			.v-icon{
				margin-right:7px
			}
			span{
				&:last-child{
					&:after{
						width:0
					}
				}
				&:after{
					content:'';
					display:inline-block;
					width:1px;
					height:10px;
					background:rgba(255,255,255,0.5);
					margin:0 10px
				}
			}
		}
		button{
			min-width:0 !important
		}
	}
	.time_are	{
		display: inline-block;
		height: 50px;
		line-height: 50px;
	}
	.login_area	{
		display: block;
		overflow: hidden;
		height: 50px;
		margin: 0 15px;
		font-size: 12px;
		line-height: 50px;
		.username {
			display: block;
			float: left;
			margin: 0 20px;
			font-size: 14px;
		}
		.authority {
			display: block;
			float: right;
		}
		.btn_log {
			display: block;
			float: left;
		}
	}
}
//btn type 버튼 타입
.btn_link{
	&.v-btn:not(.v-btn--round).v-size--default{
		padding: 0;
		text-decoration: underline;
		height: auto;
		min-width: auto !important;
		margin-top: -3px;
	}
}
//팝업 내 버튼 정렬
.v-dialog .btn_wrap{
	width: 100%;
}
//썸네일 이미지 등록 인풋 커스텀
.thumbnail_wrap{
	position: relative;
}
.thumbnail_input{
	position: absolute;
	left:0;
	top:0;
	flex-direction: column;
	max-width: 360px;
	width: 360px;
	height: 180px;
	border: 1px dashed #ccc;
	border-radius: 12px;
	padding: 25px 20px;
	.v-input__prepend-outer{
		width: 60px;
		height: 60px;
		margin: 0 auto;
		.v-input__icon{
			width: inherit;
			height: inherit;
			.v-icon.v-icon{
				font-size: 60px;
			}
		}
	}
	.v-input__slot{
		height: 100%;
	}
	.v-input__slot:before, .v-input__slot:after{
		display: none;
	}
	.v-file-input__text{
		justify-content: center;
	}
	.v-input__append-inner{
		position: absolute;
		top: -65px;
		right: 0;
	}
}
.thumbnail_area{
	position:absolute;
	top:0;
	left:0;
	width: 360px;
	height: 180px;
	border: 1px dashed #ccc;
	border-radius: 12px;
	.v-responsive{
		width: 100%;
		height: 100%;
	}
	.btn_close{
		position: absolute;
		left: 310px;
		top: 10px;
		z-index: 8;
	}
}
//답변 등록
.answerBoard{
    .v-card__title.v-card__title{
        position:relative;
    }
    .semititle_wrap{
        display:flex;
        justify-content: space-between;
        align-items:center;
    }
}
//저장 팝업
.savePopup .v-card__text{
    font-size: 18px;
    text-align: center;
}

// 문의글 테이블
.recent_table{
    &_contents .table_list{
        max-width:700px;
        padding-right:0;
    }
}
.table_list {
	display: inline-block;
	max-width: 100%;
	position: relative;
	padding-right: 25px;
	 p {
		display: none;
        margin-bottom:0;
	}
	 p:first-child {
		display: block;
        margin-bottom:0;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 700px;
        white-space:nowrap;
	}
}
.recent_table_contents{
    cursor:pointer;
}

//dialog content  어드민에서 모바일 미리보기 및 앱 화면 
.prev_mobile{
	//영역 리셋
	.px-5{
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.main_contents{
		padding:24px;
	}
	.main_contents{
		.sub_title{
			color: #9e9e9e !important;
			font-size:14px;
			padding:12px 0 20px 0
		}
	}
	//이전글, 다음글 정렬 웹어드민에서 미리보기 창 띄워보고 확인하기
	.content_list{
		.btn_area{
			padding: 0;
		}
	}
	.btn_area{
		display:flex;
		justify-content: flex-end;
		padding: 16px 24px 0 24px
	}
	.content_list{
		.btn_area{
			padding: 0;
		}
	}
	.main_title{
		font-size: 22px;
		font-weight: 500;
		letter-spacing: -0.04em;
		word-break: break-all;
		line-height: 30px;
	}
	.main_contents{
		padding-top:0 !important
	}
	//admin 내 모바일 미리보기
	.btn_cl_modal {
		height: 60px;
		font-size: 40px;
	}
	.btn_cl_modal .v-icon {
		font-size: inherit;
	}
	.file_wrap {
		padding-top: 16px;
		margin-top: 16px;
		border-top: 1px solid #f4f4f4;
	}
	.img_wrap{
		img{
			max-width: 100% !important;
			height: auto !important;
		}
	}
	.content_list{
		.btn_area{
			padding: 0;
		}
	}
}

.v-dialog__content{
	.file_list{
		.file {
			margin: 5px;
			color: #fff;
			border-radius: 5px;
			background: rgb(0 0 0 / 68%);
			i {
				font-size: 1.2em;
				color: inherit;
			}
		}
	}
	.btn_cl_modal {
		height: 60px;
		font-size: 40px;
		.v-icon{
			font-size: inherit;
		}
	}
}
//미리보기 팝업  최근 미리보기
.v-dialog .previewPopup{
    margin-left: 0;
    .v-data-table__wrapper th, .v-data-table__wrapper td{
        padding:20px  !important;
    }
	.table_vertical{
		border:none;
	}
	.v-data-table{
		th,td{
			border:1px solid rgba(0, 0, 0, 0.12) !important;
			border-collapse: collapse;
			border-width:1px 0 0 1px !important;
			&:first-child{
				border-left:0;
			}
			&:last-child{
				border-right-width:1px !important; 
			}
		}
		tr:last-child th, tr:last-child td{
			border-bottom:1px solid rgba(0, 0, 0, 0.12) !important;
		}
	}
    .popup_title{
        position:relative;
        display:flex;
        justify-content:space-between;
        .v-btn{
            background-color:transparent;
            padding:0;
            min-width: 40px !important;
            &:hover{
                background-color:transparent;
            }
            .v-icon--right{
                margin-left: 0;
            }
        }
    }
    .v-card__actions{
        display:flex;
        justify-content:flex-end;
        padding:15px;
    }
}
.main_tit {
	.main_txt {
		font-size: 22px;
		font-weight: 500;
		letter-spacing: -0.04em;
		word-break: break-all;
		line-height: 30px;
	}
	.date_txt{
		color: #999;
		font-size: 14px;
        margin-top: 5px;
	}
}
//팝업 상단 버튼 색 리셋
.popup_title .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
    background-color:transparent;
    &:hover{
        background-color:transparent;
    }
}

@media all and  (min-width: 960px) {
	.container {
    max-width: 100%;
	}
}
